import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import { format, parseISO } from 'date-fns';
import Typography from '@mui/material/Typography';
import { pointRequestStyles } from './styles';

const DetailCell = ({ row, classes }) => {
  const {
    detail_string,
    program_type_text,
    program,
    program_name,
    program_duration,
    program_start_date,
    program_summary_description,
    program_type_string,
    program_type,
    domain_name,
    vocational_activity,
    activity_name,
    activity_date,
    activity_duration,
    activity_interval,
    learning_journey_equivalent,
    inform_teaching_practice,
    related_to_units,
    student_benefit,
    type,
    is_learning_journey,
    workshop_attendances
  } = row;

  const evidences = row.evidences.map((evidence) => ({
    ...JSON.parse(evidence.file_data),
    id: evidence.id,
    fileUrl: evidence.file_url
  }));

  const renderEvidences = () => {
    return evidences.map((file) => (
      <a
        href={file.fileUrl}
        target="_blank"
        rel="noopener noreferrer"
        key={`file-${file.id}`}
      >
        <Typography color="textSecondary">{file.metadata.filename}</Typography>
      </a>
    ));
  };

  const {
    id,
    program_type_label,
    pdf_url,
    pdf_filename
  } = program || {};
  const programDesc =
    type === 'ReaPointRequest' || type === 'PointsRea'
      ? program_type_text
      : program_type_label;
  const renderFlyer = () => {
    if (!pdf_filename) {
      return [];
    }
    return [(
      <a
        href={pdf_url}
        target="_blank"
        rel="noopener noreferrer"
        key={`file-${id}`}
      >
        <Typography color="textSecondary">{pdf_filename}</Typography>
      </a>
    )];
  };

  const detailItems = [
    {
      key: 'Activity',
      values: [vocational_activity && vocational_activity.name]
    },
    {
      key: 'Domain Name',
      values: [domain_name]
    },
    {
      key: 'Program Type',
      values: [program_type_string]
    },
    {
      key: 'Program Name',
      values: [program_name]
    },
    {
      key: 'Program Date',
      values: [program_start_date]
    },
    {
      key: 'Workshops',
      values: [program_duration]
    },
    {
      key: 'Duration',
      values: [activity_duration, activity_interval]
    },
    {
      key: 'Program Type',
      values: [programDesc]
    },
    {
      key: 'Name of Activity',
      values: [activity_name]
    },
    {
      key: 'Date of Activity',
      values: [
        activity_date ? format(parseISO(activity_date), 'E dd MMM yyyy') : ''
      ],
      visible: Boolean(activity_date)
    },
    {
      key: 'Program Summary',
      component: 'div',
      values: [program_summary_description]
    },
    {
      key: 'Inform Teaching and Assessment Practice',
      values: [inform_teaching_practice],
      component: 'div'
    },
    {
      key: 'Related to Course/s',
      values: [related_to_units],
      component: 'div'
    },
    {
      key: 'Student Benefit',
      values: [student_benefit],
      component: 'div'
    },
    {
      key: 'Notes',
      values: [detail_string],
      component: 'div'
    },
    {
      key: 'Attachments',
      values: renderEvidences(),
      component: 'div'
    },
    {
      key: 'Program Flyer',
      values: renderFlyer(),
      component: 'div'
    }
  ];

  if (type === 'ReaPointRequest' || type === 'PointsRea') {
    detailItems.splice(4, 0, {
      key: 'Learning Journey Equivalent',
      values: [learning_journey_equivalent ? 'TRUE' : 'FALSE']
    });
  }

  if (['Learning Journey', 'Vocational Learning Journey'].includes(program_type) || ['learning_journey', 'vocational_learning_journey'].includes(program?.program_type)) {
    detailItems.splice(9, 0, {
      key: 'Learning Journey Achieved',
      values: [is_learning_journey ? 'Yes' : 'No']
    });
  }

  if (workshop_attendances && workshop_attendances.length > 0 ) {
    detailItems.push({
      key: 'Workshops',
      component: 'div',
      values: workshop_attendances.map((workshop, i) => `#${i + 1} ${workshop.name || `Workshop #${i+1}`}: ${workshop.attended ? workshop.points : 'Nil'} points`)
    })
  }

  if (type === 'PointsRea' || type === 'PointsVocational') {
    const { institute, requirement_1, requirement_2 } = row;
    const {
      requirement_1_name,
      requirement_1_enabled,
      requirement_2_name,
      requirement_2_enabled
    } = institute;

    if (requirement_1_name && requirement_1_enabled && requirement_1) {
      const requirementField1 = {
        key: requirement_1_name,
        values: [new Boolean(requirement_1).toString().toUpperCase()]
      };

      detailItems.push(requirementField1);
    }

    if (requirement_2_name && requirement_2_enabled && requirement_2) {
      const requirementField2 = {
        key: requirement_2_name,
        values: [new Boolean(requirement_2).toString().toUpperCase()]
      };

      detailItems.push(requirementField2);
    }
  }

  const renderDetailItem = ({
    key,
    values,
    component = 'span',
    visible = Boolean(values[0])
  }, index) => {
    if (visible) {
      return (
        <div key={`${key}-${index}`}>
          <Typography variant="body2" component="span" className={classes.span}>
            {key}:
          </Typography>
          {values.map((value, index) => (
            <Typography
              component={component}
              key={`${key}-${index}-value`}
              className={classes[component]}
            >
              {' '}
              {value}
            </Typography>
          ))}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Fragment>{detailItems.map((item, i) => renderDetailItem(item, i))}</Fragment>
  );
};

export default withStyles(DetailCell, pointRequestStyles, { withTheme: true });
