import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import WaveTextField from '../form/WaveTextField';
import { DefaultCheckbox } from '@common/components/default-checkbox';
import { Typography } from '@mui/material';

const styles = (theme) => ({
  formLeft: {
    paddingRight: theme.typography.pxToRem(13),
    paddingBottom: theme.typography.pxToRem(10)
  },
  formRight: {
    paddingLeft: theme.typography.pxToRem(13),
    paddingBottom: theme.typography.pxToRem(10)
  },
  profilingToolLabelFields: {
    marginBottom: theme.typography.pxToRem(10)
  },
  sectionHeaders: {
    color: theme.typography.color.primary,
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 600,
    lineHeight: 2.15,
    marginBottom: theme.typography.pxToRem(12),
    textTransform: 'uppercase'
  }
});

class EmploymentTypeForm extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid
          container
          className={classes.createLocationFields}
          justifyContent="space-between"
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6} className={classes.formLeft}>
                <WaveTextField
                  label="Name"
                  maxLength={30}
                  {...this.props.makeFieldProps('name')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.formLeft}>
            <WaveTextField
              label="Professional Points Min Requirement"
              {...this.props.makeFieldProps('professional_points')}
            />
          </Grid>
          <Grid item xs={6} className={classes.formRight}>
            <WaveTextField
              label="Professional Learning Journey Min Requirement"
              {...this.props.makeFieldProps('num_lj_required')}
            />
          </Grid>
          <Grid item xs={6} className={classes.formLeft}>
            <WaveTextField
              label="Vocational Points Min Requirement"
              {...this.props.makeFieldProps('vocational_points')}
            />
          </Grid>
          <Grid item xs={6} className={classes.formRight}>
            <WaveTextField
              label="Vocational Learning Journey Min Requirement"
              {...this.props.makeFieldProps('num_vocational_lj_required')}
            />
          </Grid>
        </Grid>
        <Typography className={classes.sectionHeaders} mt={2}>
          Features
        </Typography>
        <Grid
          container
          className={classes.createLocationFields}
          justifyContent="space-between"
        >
          <Grid item xs={12} className={classes.formLeft}>
            <DefaultCheckbox
              label="Profiling Tool Required"
              checkboxValue={true}
              unCheckValue={false}
              {...this.props.makeFieldProps('profiling_tool_required')}
            />
          </Grid>
          <Grid item xs={12} className={classes.formLeft}>
            <DefaultCheckbox
              label="VCM"
              checkboxValue={true}
              unCheckValue={false}
              {...this.props.makeFieldProps('vcm_enabled')}
            />
          </Grid>
          <Grid item xs={12} className={classes.formLeft}>
            <DefaultCheckbox
              label="Enable Supervisor Step"
              checkboxValue={true}
              unCheckValue={false}
              {...this.props.makeFieldProps(
                'profiling_require_supervisor_step'
              )}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(EmploymentTypeForm, styles, { withTheme: true });
