import React from 'react';
import { withStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DefaultEmail from '@common/config/Tooltip/DefaultEmail';
import InstituteLogoText from '@common/config/Tooltip/InstituteLogoText';
import RegistrationCutoff from '@common/config/Tooltip/RegistrationCutoff';
import TooltipTitle from '@common/config/Tooltip/TooltipTitle';
import SelectFieldOptions from '@common/config/SelectFieldOptions';
import StyledDivider from '@common/components/misc/StyledDivider';
import WaveSelectField from '@common/components/form/WaveSelectField';
import WaveTextField from '@common/components/form/WaveTextField';
import InfoIcon from '@common/icons/InfoIcon';
import { DefaultCheckbox } from '@common/components/default-checkbox';
import { instituteDetailsStyles } from './styles';
import MetadataService from '@common/services/MetadataService';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import SSODomainName from '@common/config/Tooltip/SSODomainName';

class InstituteDetails extends React.Component {
  meta = new MetadataService();

  errorHelpers = (field) => {
    if (this.props.validationErrors.hasOwnProperty(field)) {
      return this.props.validationErrors[field][0];
    }
    return null;
  };

  errorStatus = (field) => {
    return this.props.validationErrors.hasOwnProperty(field);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let institute = { ...this.props.instituteDetails };
    institute[name] = value;

    this.props.onInstituteChange(institute);
  };

  handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let institute = { ...this.props.instituteDetails };
    if (target.checked) {
      institute[name].push(value);
    } else {
      const idx = institute[name].indexOf(value);
      if (idx > -1) {
        institute[name].splice(idx, 1);
      }
    }

    this.props.onInstituteChange(institute);
  };

  makeFieldProps = (field) => {
    return {
      error: this.errorStatus(field),
      helperText: this.errorHelpers(field),
      name: field,
      onChange: this.handleInputChange,
      value: this.props.instituteDetails[field]
    };
  };

  render() {
    const { classes, instituteDetails, timezoneOptions, user } = this.props;
    const instituteMeta = this.meta.get('institutes');
    const {
      vocational_point_approval_email_to: { options: vocationalPointEmailTo },
      professional_point_approval_email_to: {
        options: professionalPointEmailTo
      },
      allow_supervisor_to_action: { options: allowSupervisorToAction },
      ical_type: { options: icalTypeOptions }
    } = instituteMeta;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.instituteDetailsContainer}>
            <form noValidate autoComplete="off">
              <Typography className={classes.sectionHeaders}>
                Institute Branding
              </Typography>
              <Grid container>
                <Grid item className={classes.formLeft} xs={6}>
                  <WaveTextField
                    label="Institute Name"
                    maxLength={50}
                    {...this.makeFieldProps('name')}
                  />
                </Grid>
                <Grid item className={classes.formRight} xs={6}>
                  <WaveTextField
                    fileUpload={
                      <InputAdornment position="end">
                        <Button
                          className={classes.logoUpload}
                          color="secondary"
                          component="label"
                          size="small"
                          variant="contained"
                        >
                          <Typography variant="button">Browse</Typography>
                          <input
                            accept=".png"
                            className={classes.hideInput}
                            onChange={(event) => {
                              this.props.onLogoChange(event.target.files[0]);
                              event.target.value = null;
                            }}
                            type="file"
                          />
                        </Button>
                      </InputAdornment>
                    }
                    label="Institute Logo"
                    tooltipProps={{
                      placement: 'top',
                      titleComponent: <InstituteLogoText />,
                      tooltipContainer: classes.tooltipContainer,
                      tooltipPopper: classes.popper
                    }}
                    value={instituteDetails.logo_filename}
                  />
                </Grid>
                <Grid item xs={6} className={classes.formLeft}>
                  <WaveTextField
                    label="Primary Color"
                    tooltipProps={{
                      placement: 'top',
                      titleComponent: (
                        <Typography className={classes.tooltipText}>
                          {TooltipTitle.primaryColour}
                        </Typography>
                      ),
                      tooltipContainer: classes.tooltipContainer,
                      tooltipPopper: classes.popper
                    }}
                    {...this.makeFieldProps('primary_color')}
                  />
                </Grid>
                <Grid item xs={6} className={classes.formRight}>
                  <WaveTextField
                    label="Primary Color - Light"
                    tooltipProps={{
                      placement: 'top',
                      titleComponent: (
                        <Typography className={classes.tooltipText}>
                          {TooltipTitle.primaryLightColour}
                        </Typography>
                      ),
                      tooltipContainer: classes.tooltipContainer,
                      tooltipPopper: classes.popper
                    }}
                    {...this.makeFieldProps('primary_light_color')}
                  />
                </Grid>
                <Grid item xs={6} className={classes.formLeft}>
                  <WaveTextField
                    label="Secondary Color"
                    tooltipProps={{
                      placement: 'top',
                      titleComponent: (
                        <Typography className={classes.tooltipText}>
                          {TooltipTitle.secondaryColour}
                        </Typography>
                      ),
                      tooltipContainer: classes.tooltipContainer,
                      tooltipPopper: classes.popper
                    }}
                    {...this.makeFieldProps('secondary_color')}
                  />
                </Grid>
              </Grid>
              <StyledDivider extraStyles={classes.divider} />
              <Typography className={classes.sectionHeaders}>
                Basic Information
              </Typography>
              <Grid container>
                <Grid item className={classes.formLeft} xs={6}>
                  <WaveSelectField
                    label="Timezone"
                    menuItems={timezoneOptions}
                    {...this.makeFieldProps('timezone')}
                  />
                </Grid>
                <Grid item className={classes.formRight} xs={6}>
                  <WaveSelectField
                    label="Points Anniversary"
                    menuItems={SelectFieldOptions.pointsAnniversary}
                    {...this.makeFieldProps('points_anniversary_month')}
                  />
                </Grid>
                <Grid item xs={6} className={classes.formLeft}>
                  <WaveTextField
                    label="From Email Name"
                    {...this.makeFieldProps('from_email_name')}
                  />
                </Grid>
                <Grid item xs={6} className={classes.formRight}>
                  <WaveTextField
                    label="From Email Address"
                    readOnly={!user.is_super_admin}
                    tooltipProps={{
                      placement: 'top',
                      titleComponent: <DefaultEmail />,
                      tooltipContainer: classes.tooltipContainer,
                      tooltipPopper: classes.popper
                    }}
                    type="email"
                    {...this.makeFieldProps('from_email_address')}
                  />
                </Grid>
              </Grid>
              <StyledDivider extraStyles={classes.divider} />
              <Typography className={classes.sectionHeaders}>
                Programs
              </Typography>
              <Grid container>
                <Grid item className={classes.registrationCutoff} xs={6}>
                  <WaveTextField
                    label="Registration Cutoff (Days)"
                    tooltipProps={{
                      placement: 'top',
                      titleComponent: <RegistrationCutoff />,
                      tooltipContainer: classes.tooltipContainer,
                      tooltipPopper: classes.popper
                    }}
                    {...this.makeFieldProps('registration_cutoff_days')}
                  />
                </Grid>
                <Grid item className={classes.registrationCutoff} xs={12}>
                  <DefaultCheckbox
                    label="Registrations require manual approval"
                    checkboxValue={true}
                    unCheckValue={false}
                    {...this.makeFieldProps(
                      'registrations_require_approval_enabled'
                    )}
                  />
                </Grid>
              </Grid>
              <StyledDivider extraStyles={classes.divider} />
              <Grid container spacing={2}>
                <Grid item>
                  <Grid
                    container
                    direction={'row'}
                    alignItems="baseline"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography className={classes.slimHeader}>
                        VCM
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip
                        classes={{
                          popper: classes.popper,
                          tooltip: classes.tooltipContainer
                        }}
                        placement="top"
                        title={
                          <Typography className={classes.tooltipText}>
                            Turn on the VCM for this institute
                          </Typography>
                        }
                      >
                        <Typography fontSize={14}>ⓘ</Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <DefaultCheckbox
                    label="Enable VCM"
                    checkboxValue={true}
                    unCheckValue={false}
                    {...this.makeFieldProps('vcm_enabled')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.sectionHeaders}>
                    Course/UNIT MAtrix
                  </Typography>

                  <Button component={Link} to={`/institutes/${instituteDetails.id}/manage_vcm_course_unit`} color="secondary" size="small" variant="contained">
                    <Typography variant="button">
                      MANAGE COURSE/UNIT MATRIX
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <StyledDivider extraStyles={classes.divider} />
              <Typography className={classes.sectionHeaders}>
                Educator Web App
              </Typography>
              <Grid container>
                <Grid item className={classes.registrationCutoff} xs={12}>
                  <DefaultCheckbox
                    label="Enable educator points entry"
                    checkboxValue={true}
                    unCheckValue={false}
                    {...this.makeFieldProps('educator_points_entry_enabled')}
                  />
                </Grid>
                <Grid item className={classes.formLeft} xs={6}>
                  <DefaultCheckbox
                    label="Enable points file attachment"
                    checkboxValue={true}
                    unCheckValue={false}
                    {...this.makeFieldProps('points_file_attachment_enabled')}
                  />
                </Grid>
                <Grid item className={classes.formRight} xs={6}>
                  <DefaultCheckbox
                    label="File attachment required"
                    checkboxValue={true}
                    unCheckValue={false}
                    {...this.makeFieldProps('file_attachment_required')}
                  />
                </Grid>
                <Grid item className={classes.formLeft} xs={6}>
                  <FormLabel className={classes.checkboxLabel}>
                    Vocational points approval email to
                  </FormLabel>
                  <FormGroup row>
                    {Object.keys(vocationalPointEmailTo).map(
                      (emailTo, index) => (
                        <FormControlLabel
                          key={index}
                          className={classes.checkboxField}
                          control={
                            <Checkbox
                              checked={this.props.instituteDetails.vocational_point_approval_email_to.includes(
                                vocationalPointEmailTo[emailTo]
                              )}
                              name="vocational_point_approval_email_to"
                              value={vocationalPointEmailTo[emailTo]}
                              onChange={this.handleCheckboxChange}
                            />
                          }
                          label={emailTo}
                        />
                      )
                    )}
                  </FormGroup>
                </Grid>
                <Grid item className={classes.formRight} xs={6}>
                  <FormLabel className={classes.checkboxLabel}>
                    Professional points approval email to
                  </FormLabel>
                  <FormGroup row>
                    {Object.keys(professionalPointEmailTo).map(
                      (emailTo, index) => (
                        <FormControlLabel
                          key={index}
                          className={classes.checkboxField}
                          control={
                            <Checkbox
                              checked={this.props.instituteDetails.professional_point_approval_email_to.includes(
                                professionalPointEmailTo[emailTo]
                              )}
                              name="professional_point_approval_email_to"
                              value={professionalPointEmailTo[emailTo]}
                              onChange={this.handleCheckboxChange}
                            />
                          }
                          label={emailTo}
                        />
                      )
                    )}
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel className={classes.checkboxLabel}>
                    Allow Supervisor role to action
                  </FormLabel>
                  <FormGroup row>
                    {Object.keys(allowSupervisorToAction).map(
                      (action, index) => (
                        <FormControlLabel
                          key={index}
                          className={classes.checkboxField}
                          control={
                            <Checkbox
                              checked={this.props.instituteDetails.allow_supervisor_to_action.includes(
                                allowSupervisorToAction[action]
                              )}
                              name="allow_supervisor_to_action"
                              value={allowSupervisorToAction[action]}
                              onChange={this.handleCheckboxChange}
                            />
                          }
                          label={action}
                        />
                      )
                    )}
                  </FormGroup>
                </Grid>
              </Grid>
              <StyledDivider extraStyles={classes.divider} />
              <Typography className={classes.sectionHeaders}>
                Calendar Settings
              </Typography>
              <Grid container>
                <Grid item xs={6} className={classes.formLeft}>
                  <WaveSelectField
                    label="Calendar Attachment Type"
                    menuItems={icalTypeOptions}
                    tooltipProps={{
                      placement: 'top',
                      titleComponent: (
                        <Typography className={classes.tooltipText}>
                          {TooltipTitle.icalType}
                        </Typography>
                      ),
                      tooltipContainer: classes.tooltipContainer,
                      tooltipPopper: classes.popper
                    }}
                    {...this.makeFieldProps('ical_type')}
                  />
                </Grid>
                <Grid item xs={6} className={classes.formRight}>
                  <DefaultCheckbox
                    label="Enable Add All to Calendar"
                    checkboxValue={true}
                    unCheckValue={false}
                    tooltipProps={{
                      placement: 'right',
                      titleComponent: (
                        <Typography className={classes.tooltipText}>
                          {TooltipTitle.addAllToCalendarEnabled}
                        </Typography>
                      ),
                      tooltipContainer: classes.tooltipContainer,
                      tooltipPopper: classes.popper
                    }}
                    {...this.makeFieldProps('add_all_to_calendar_enabled')}
                  />
                </Grid>
              </Grid>
              <StyledDivider extraStyles={classes.divider} />
              <Typography className={classes.sectionHeaders}>
                Additional Reporting Requirements
              </Typography>
              <Grid container>
                <Grid item xs={6} className={classes.formLeft}>
                  <WaveTextField
                    label="Requirement 1 Name"
                    {...this.makeFieldProps('requirement_1_name')}
                  />
                </Grid>
                <Grid item xs={6} className={classes.formRight}>
                  <DefaultCheckbox
                    label="Requirement 1 Enabled"
                    checkboxValue={true}
                    unCheckValue={false}
                    {...this.makeFieldProps('requirement_1_enabled')}
                  />
                </Grid>
                <Grid item xs={6} className={classes.formLeft}>
                  <WaveTextField
                    label="Requirement 2 Name"
                    {...this.makeFieldProps('requirement_2_name')}
                  />
                </Grid>
                <Grid item xs={6} className={classes.formRight}>
                  <DefaultCheckbox
                    label="Requirement 2 Enabled"
                    checkboxValue={true}
                    unCheckValue={false}
                    {...this.makeFieldProps('requirement_2_enabled')}
                  />
                </Grid>
              </Grid>
              <StyledDivider extraStyles={classes.divider} />
              <Typography className={classes.sectionHeaders}>
                Custom User Fields
              </Typography>
              <Grid container>
                <Grid item xs={6} className={classes.formLeft}>
                  <WaveTextField
                    label="Custom User Field 1 Name"
                    {...this.makeFieldProps('custom_user_field_1')}
                  />
                </Grid>
                <Grid item xs={6} className={classes.formRight}>
                  <DefaultCheckbox
                    label="Custom Field 1 Enabled"
                    checkboxValue={true}
                    unCheckValue={false}
                    {...this.makeFieldProps('custom_user_field_1_enabled')}
                  />
                </Grid>
                <Grid item xs={6} className={classes.formLeft}>
                  <WaveTextField
                    label="Custom User Field 2 Name"
                    {...this.makeFieldProps('custom_user_field_2')}
                  />
                </Grid>
                <Grid item xs={6} className={classes.formRight}>
                  <DefaultCheckbox
                    label="Custom Field 2 Enabled"
                    checkboxValue={true}
                    unCheckValue={false}
                    {...this.makeFieldProps('custom_user_field_2_enabled')}
                  />
                </Grid>
              </Grid>

              <StyledDivider extraStyles={classes.divider} />
              <Typography className={classes.sectionHeaders}>
                Single Sign-on
              </Typography>
              <Grid container>
                <Grid item className={classes.formLeft} xs={12}>
                  <DefaultCheckbox
                    label="SSO enabled"
                    checkboxValue={true}
                    unCheckValue={false}
                    {...this.makeFieldProps('sso_enabled')}
                  />
                </Grid>
                <Grid item xs={6} className={classes.formLeft}>
                  <WaveTextField
                    label="Institute domain names"
                    {...this.makeFieldProps('sso_domain_name')}
                    tooltipProps={{
                      placement: 'top',
                      titleComponent: <SSODomainName />,
                      tooltipContainer: classes.tooltipContainer,
                      tooltipPopper: classes.popper
                    }}
                  />
                </Grid>
                <Grid item xs={6} className={classes.formRight}>
                  <WaveTextField
                    label="Identity Provider"
                    {...this.makeFieldProps('sso_identity_provider')}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(InstituteDetails, instituteDetailsStyles, {
  withTheme: true
});
