import PubSub from 'pubsub-js';
import React, { Fragment } from 'react';
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { LocalizationProvider  } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ApiService from '../../common/services/ApiService';
import Utils from '../../common/services/Utils';
import Header from './header/Header';
import ManageProfiles from './profiling/ManageProfiles';
import ManageInstitute from './institute/ManageInstitute';
import ProgramsList from './programs/ProgramsList';
import ProgramManage from './programs/ProgramManage';
import RegistrationList from './registrations/RegistrationList';
import Reports from './reports/Reports';
import UsersList from './users/UsersList';
import UserManage from './users/UserManage';
import UsersImport from './users/import/UsersImport';
import ImportPoints from './users/import/ImportPoints';
import { ImportPointsNoApproval } from './users/import/ImportPointsNoApproval';
import poweredByLogo from '../../images/powered-by-wave.png';
import RegistrationsImport from './programs/import-registrations/RegistrationsImport';
import VcmList from './vcm/VcmList';
import VcmDetails from './vcm/VcmDetails';
import VcmHistory from './vcm/VcmHistory';
import VcmManagerCourseUnits from './vcm/import/VcmManagerCourseUnits'

// https://material-ui.com/customization/themes/#typography-font-size
// https://github.com/mui-org/material-ui/blob/303199d39b42a321d28347d8440d69166f872f27/packages/material-ui/src/styles/createTypography.js
// MUI uses rem instead of px. Cant use pxToRem (in Typography component) when defining theme & typography
function pxToRem(value) {
  const fontSize = 14; //mui default
  const htmlFontSize = 16; //browser default
  const coef = fontSize / 14; //coefficient
  return `${(value / htmlFontSize) * coef}rem`;
}

const modalStyles = {
  button: {
    marginBottom: pxToRem(6)
  },
  closeIcon: {
    color: '#1f1f1f',
    cursor: 'pointer',
    opacity: 0.3
  },
  container: {
    backgroundColor: '#fff',
    left: '50%',
    margin: '0',
    padding: `${pxToRem(24)} ${pxToRem(30)}`,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%'
  },
  errorMessage: {
    color: '#1f1f1fde',
    fontSize: pxToRem(16),
    lineHeight: 1.5,
    marginBottom: pxToRem(30)
  },
  header: {
    marginBottom: pxToRem(20)
  }
};

class AdminApp extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.errorQueue = [];
    this.state = {
      themeName: 'theme_' + props.institute.id,
      user: props.user,
      errorsOpen: false,
      errorMessageInfo: {},
      errorModal: false,
      modalErrorMessage: null,
      openProfilesCount: 0,
      pendingRegistrationsCount: 0,
      vcmsInReviewCount: 0
    };

    this.subscriptions = [
      PubSub.subscribe('ERROR', (topic, data) => {
        this.handleError(data);
      }),
      PubSub.subscribe('MODAL_ERROR', (topic, data) => {
        this.setState({ errorModal: true, modalErrorMessage: data });
      }),
      PubSub.subscribe('SESSION_ERROR', (topic, data) => {
        alert(data);
        window.location = '/users/sign_in';
      })
    ];
  }

  componentDidMount() {
    if (this.state.user.is_supervisor) {
      this.interval = setInterval(() => this.checkNotifications(), 90000);
      this.checkNotifications();
    }
  }
  componentWillUnmount() {
    if (this.state.user.is_supervisor) {
      clearInterval(this.interval);
    }
    this.subscriptions.forEach(PubSub.unsubscribe);
  }

  checkNotifications = () => {
    this.api.query('/api/v1/profiles/check').then((json) => {
      this.setState({
        openProfilesCount: json.open_count,
        pendingRegistrationsCount: json.pending_registrations_count
      });
    });

    if (this.props.institute.vcm_enabled) {
      this.api.query('/api/v1/vcms/check').then((json) => {
        this.setState({
          vcmsInReviewCount: json.data.in_review_count
        });
      }).catch((error) => {
        console.error(error);
      });
    }
  };

  handleError = (message) => {
    console.error('Got message: ' + message);

    this.errorQueue.push({
      message,
      key: new Date().getTime()
    });

    if (this.state.errorsOpen) {
      // immediately begin dismissing current message
      // to start showing new one
      this.setState({ open: false });
    } else {
      this.processErrorQueue();
    }
  };

  urlReloadCheck = (instituteId) => {
    // Do a full page reload
    // It's a bit crude but it's easier than ensuring every component re-loads data from API.

    const urlRegex = /^\/institutes.*/g;
    const numRegex = /\d+/g;
    const currentUrl = window.location.href;
    const isInstituteUrl = urlRegex.test(currentUrl);

    if (isInstituteUrl) {
      const urlCheck = currentUrl.match(urlRegex).toString();
      const instituteIdCheck = urlCheck.match(numRegex).toString();

      // don't reload if on same instituteId
      if (instituteId != instituteIdCheck) {
        const instituteUrl = urlCheck.replace(numRegex, instituteId);
        const newUrl = currentUrl.replace(urlCheck, instituteUrl);
        window.history.pushState(null, '', newUrl);
        window.location.reload();
      }
    } else {
      window.location.reload();
    }
  };

  handleInstituteChange = (instituteId) => {
    this.api
      .query('/api/v1/institutes/' + instituteId + '/select') // set the institute in the session.
      .then((res) => {
        this.urlReloadCheck(instituteId);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  makeTheme() {
    var themeName = this.state.themeName || 'theme_default';
    var themeBase = {};
    if (window.themes.hasOwnProperty(themeName)) {
      themeBase = window.themes[themeName];
    } else {
      console.warn('Theme not found ' + themeName);
    }

    themeBase.overrides = {
      MuiButton: {
        root: {
          padding: `${pxToRem(9)} ${pxToRem(12)}`
        },
        sizeSmall: {
          padding: `${pxToRem(9)} ${pxToRem(12)}`
        }
      },
      MuiDivider: {
        root: {
          backgroundColor: '#303030',
          border: '0.5px solid #303030',
          height: 0,
          opacity: 0.5
        }
      },
      MuiFormLabel: {
        root: {
          color: '#1f1f1fde',
          opacity: 0.7,
          '&.Mui-focused': {
            color: '#1f1f1fde',
            opacity: 0.7
          },
          '&.Mui-error': {
            opacity: 1
          }
        }
      },
      MuiInput: {
        underline: {
          '&:after': {
            borderBottom: `2px solid ${themeBase.palette.secondary.main}`
          },
          '&:before': {
            borderBottom: '2px solid #cecece'
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `2px solid ${themeBase.palette.secondary.main}`
          }
        }
      },
      MuiMenuItem: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#f4f1f1'
          }
        }
      },
      MuiTableCell: {
        root: {
          padding: '14px 14px 14px 24px'
        }
      },
      MuiTab: {
        root: {
          minWidth: '160px'
        }
      }
    };

    if (Utils.isIE()) {
      // IE11 Date picker fix CHISHEP-314
      themeBase.overrides['MuiDialog'] = {
        paper: {
          overflowY: 'hidden'
        }
      };
      themeBase.overrides['MuiDialogActions'] = {
        root: {
          justifyContent: false
        }
      };
    }

    themeBase.typography = {
      fontFamily: 'IBM Plex Sans, sans-serif',
      color: {
        primary: '#1f1f1f'
      },
      button: {
        color: themeBase.palette.secondary.contrastText,
        fontFamily: 'IBM Plex Sans, medium',
        fontSize: pxToRem(13),
        fontWeight: 500
      },
      h5: {
        color: themeBase.palette.primary.main,
        fontFamily: 'IBM Plex Sans, medium',
        fontSize: pxToRem(23),
        fontWeight: 500
      },
      h6: {
        color: '#1f1f1f',
        fontFamily: 'IBM Plex Sans, medium',
        fontSize: pxToRem(20),
        fontWeight: 500
      }
    };
    return createTheme(adaptV4Theme(themeBase));
  }

  render() {
    const theme = this.makeTheme();
    const { message, key } = this.state.errorMessageInfo;

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Router>
              <Fragment>
                <Header
                  institute={this.props.institute}
                  institutes={this.props.institutes}
                  onInstituteChange={this.handleInstituteChange}
                  user={this.props.user}
                  openProfilesCount={this.state.openProfilesCount}
                  pendingRegistrationsCount={this.state.pendingRegistrationsCount}
                  vcmsInReviewCount={this.state.vcmsInReviewCount}
                />
                <div style={{ marginLeft: 80, marginRight: 80 }}>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/programs" />}
                  />
                  <Route
                    exact
                    path="/programs"
                    render={(props) => (
                      <ProgramsList {...props} user={this.props.user} />
                    )}
                  />
                  <Route
                    path="/programs/:id"
                    render={(props) => (
                      <ProgramManage
                        {...props}
                        key={props.match.params.id}
                        user={this.props.user}
                      />
                    )}
                  />
                  <Route
                    path="/import_registrations/:program_id"
                    render={(props) => (
                      <RegistrationsImport
                        {...props}
                        key={props.match.params.id}
                        user={this.props.user}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/registrations"
                    render={(props) => (
                      <RegistrationList {...props} user={this.props.user} />
                    )}
                  />
                  <Route
                    exact
                    path="/profiling"
                    render={(props) => (
                      <ManageProfiles
                        user={this.props.user}
                        openProfilesCount={this.state.openProfilesCount}
                      />
                    )}
                  />
                  <Route
                    path="/profiling/:educator_id"
                    render={(props) => (
                      <ManageProfiles
                        user={this.props.user}
                        {...props}
                        openProfilesCount={this.state.openProfilesCount}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/reports"
                    render={() => <Redirect to="reports/passports" />}
                  />
                  <Route
                    path="/reports/:report_name"
                    render={(props) => (
                      <Reports
                        {...props}
                        key={props.match.params.report_name}
                        user={this.props.user}
                        institute={this.props.institute}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/vcm"
                    render={(props) => (
                      <VcmList {...props} user={this.props.user} institute={this.props.institute} />
                    )}
                  />
                   <Route
                    exact
                    path="/vcm/:id"
                    render={(props) => (
                      <VcmDetails {...props} user={this.props.user} institute={this.props.institute} />
                    )}
                  />
                   <Route
                    exact
                    path="/vcm/:id/history"
                    render={(props) => (
                      <VcmHistory {...props} user={this.props.user} />
                    )}
                  />
                  <Route
                    exact
                    path="/users"
                    render={(props) => (
                      <UsersList {...props} user={this.props.user} institute={this.props.institute} />
                    )}
                  />
                  <Route
                    exact
                    path="/institutes/:id"
                    render={() => (
                      <Redirect
                        to={`/institutes/${this.props.institute.id}/institute_details`}
                      />
                    )}
                  />
                  <Switch>
                    <Route
                      exact
                      path="/institutes/:id/manage_vcm_course_unit"
                      render={(props) => (
                        <VcmManagerCourseUnits
                          {...props}
                          user={this.props.user}
                          institute={this.props.institute}
                        />
                      )}
                    />
                    <Route
                      path="/institutes/:id/:section"
                      render={(props) => (
                        <ManageInstitute
                          {...props}
                          key={`${props.match.params.section}/${props.match.params.id}`}
                          user={this.props.user}
                          institute={this.props.institute}
                        />
                      )}
                    />
                  </Switch>
                  <Route
                    path="/users/:id"
                    render={(props) => (
                      <UserManage
                        {...props}
                        key={props.match.params.id}
                        user={this.props.user}
                        institute={this.props.institute}
                      />
                    )}
                  />
                  <Route
                    path="/users_import"
                    isActive={this.userImport}
                    render={(props) => <UsersImport {...props} institute={this.props.institute} />}
                  />
                  <Route
                    exact
                    path="/import_points"
                    render={(props) => <ImportPoints {...props} />}
                  />
                  <Route
                    exact
                    path="/import_points_no_approval"
                    render={(props) => (
                      <ImportPointsNoApproval
                        {...props}
                        institute={this.props.institute}
                      />
                    )}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: 40
                    }}
                  >
                    <a href="https://wavedigital.com.au" target="_blank">
                      <img src={poweredByLogo} />
                    </a>
                  </div>
                </div>
              </Fragment>
            </Router>
            <Snackbar
              key={key}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              open={this.state.errorsOpen}
              autoHideDuration={5000}
              onClose={this.handleErrorClose}
              message={
                <span id="message-id" style={{ color: 'pink' }}>
                  {message}
                </span>
              }
              action={[
                <IconButton key="close" color="inherit" onClick={this.handleErrorClose} size="large">
                  <CloseIcon />
                </IconButton>
              ]}
              TransitionProps={{
                onExited: this.handleErrorExited
              }} />
            <Modal
              open={Boolean(this.state.errorModal)}
              onClose={() => this.setState({ errorModal: false })}
            >
              <div style={modalStyles.container}>
                <Grid
                  container
                  style={modalStyles.header}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">Error!</Typography>
                  <a onClick={() => this.setState({ errorModal: false })}>
                    <CloseIcon style={modalStyles.closeIcon} />
                  </a>
                </Grid>
                <Typography style={modalStyles.errorMessage}>
                  {this.state.modalErrorMessage}
                </Typography>
                <Button
                  color="secondary"
                  onClick={() => this.setState({ errorModal: false })}
                  size="small"
                  style={modalStyles.button}
                  variant="contained"
                >
                  <Typography variant="button">Okay</Typography>
                </Button>
              </div>
            </Modal>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  processErrorQueue = () => {
    if (this.errorQueue.length > 0) {
      this.setState({
        errorMessageInfo: this.errorQueue.shift(),
        errorsOpen: true
      });
    }
  };

  handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ errorsOpen: false });
  };

  handleErrorExited = () => {
    this.processErrorQueue();
  };
}

export default AdminApp;
