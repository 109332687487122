import React from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import WaveSelectField from '../../../../common/components/form/WaveSelectField';


const styles = (theme) => ({
  csvFile: {
    paddingLeft: theme.typography.pxToRem(15)
  },
  fileImportHeaders: {
    paddingBottom: theme.typography.pxToRem(15)
  },
  fileUploadContainer: {
    padding: `${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(
      35
    )} ${theme.typography.pxToRem(25)}`
  },
  hideInput: {
    display: 'none'
  },
  uploadCsv: {
    marginBottom: theme.typography.pxToRem(30)
  }
});

class ImportForm extends React.Component {

  render() {
    const { classes, file, onCsvFileChange, year, yearsRange, onYearChange } = this.props;

    return (
      <Paper className={classes.fileUploadContainer}>
        <Grid container className={classes.container} spacing={2}>
        <Grid item xs={6}>
            <Typography className={classes.fileImportHeaders} variant="h6">
              Effective Year
            </Typography>
            <WaveSelectField
              noValidation
              label="Year"
              menuItems={yearsRange}
              name="year"
              value={year}
              onChange={onYearChange}
            />
            
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.fileImportHeaders} variant="h6">
              File
            </Typography>
            <Grid container alignItems="center" className={classes.uploadCsv}>
              <Button
                color="secondary"
                component="label"
                size="small"
                variant="contained"
              >
                Upload
                <input
                  className={classes.hideInput}
                  accept="text/csv"
                  onChange={(event) => {
                    this.props.onCsvFileChange(event.target.files[0]);
                    event.target.value = null;
                  }}
                  type="file"
                />
              </Button>
              {this.props.file === null ? (
                ''
              ) : (
                <Typography className={classes.csvFile} variant="h6">
                  {this.props.file.name}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(ImportForm, styles, { withTheme: true });
