import React, { useCallback, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ApiService from '@common/services/ApiService';
import { DefaultModal } from '@common/components/default-modal';
import { DefaultButton } from '@common/components/default-button';
import { withStyles } from 'tss-react/mui';
import { unitResponsesStyles } from './styles';
import { vcmUnitResponseStatusOptions } from './options';

const api = new ApiService();
const VcmApproveModal = ({
  classes,
  isOpened,
  onClose,
  onRefresh,
  unitResponses,
  vcm
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const canApproveVcm = useMemo(
    () =>
      unitResponses.every(
        (unitResponse) =>
          unitResponse.status === vcmUnitResponseStatusOptions.approved.id
      ),
    [unitResponses]
  );

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    try {
      const url = `/api/v1/vcms/${vcm.id}/approve`;
      const res = await api.put(url);

      if (res.status === 200) {
        handleClose();
        onRefresh();
      } else if (res.status === 422 || res.status === 400) {
        setErrorMessage(res.json.message);
      } else {
        console.error('Unknown response', res);
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleClose = useCallback(() => {
    setErrorMessage(null);
    onClose();
  }, [setErrorMessage, onClose]);

  return (
    <DefaultModal
      isOpened={isOpened}
      isLoading={isLoading}
      onClose={handleClose}
      title="Approve VCM"
      action={
        canApproveVcm ? (
          <DefaultButton
            className={classes.button}
            onClick={handleSubmit}
            color="secondary"
            size="small"
            variant="contained"
          >
            Approve
          </DefaultButton>
        ) : (
          <DefaultButton
            className={classes.button}
            onClick={handleClose}
            size="small"
            variant="contained"
          >
            Close
          </DefaultButton>
        )
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="error">{errorMessage}</Typography>
        </Grid>
        <Grid item xs={12} mb={4}>
          <Typography>
            {canApproveVcm
              ? "This will notify the Educator and close off this revision of the VCM as approved."
              : 'You must approve all units first'}
          </Typography>
        </Grid>
      </Grid>
    </DefaultModal>
  );
};

export default withStyles(VcmApproveModal, unitResponsesStyles, {
  withTheme: true
});
